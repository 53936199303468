import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
    {
        path: "/",
        redirect: "/TablePage"
    },
    {
        path: "/HomePage",
        name: 'HomePage',
        meta: {
            pageTitle: "如猫添翼",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/HomePage.vue"),
    },
    // {
    //     path: "/ExamplePage",
    //     name: 'ExamplePage',
    //     meta: {
    //         pageTitle: "案例",
    //         requireAuth: false,
    //         keepAlive: true
    //     },
    //     component: () => import("p/ExamplePage.vue"),
    // },
    {
        path: "/DemonstrationPage",
        name: 'DemonstrationPage',
        meta: {
            pageTitle: "演示",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/DemonstrationPage.vue"),
    },
    {
        path: "/VideoPageListPage",
        name: 'VideoPageListPage',
        meta: {
            pageTitle: "如猫添翼视频墙",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/VideoPageListPage.vue"),
    },
    {
        path: "/VideoPage",
        name: 'VideoPage',
        meta: {
            pageTitle: "如猫添翼视频墙",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/VideoPage.vue"),
    },
    {
        path: "/Demo1Page",
        name: 'Demo1Page',
        meta: {
            pageTitle: "商家发布",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo1Page.vue"),
    },
    {
        path: "/Demo2Page",
        name: 'Demo2Page',
        meta: {
            pageTitle: "商家发布完成",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo2Page.vue"),
    },
    {
        path: "/Demo3Page",
        name: 'Demo3Page',
        meta: {
            pageTitle: "查看团购",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo3Page.vue"),
    },
    {
        path: "/Demo4Page",
        name: 'Demo4Page',
        meta: {
            pageTitle: "达人发布",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo4Page.vue"),
    },
    {
        path: "/Demo5Page",
        name: 'Demo5Page',
        meta: {
            pageTitle: "达人发布完成",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo5Page.vue"),
    },
    {
        path: "/TablePage",
        name: 'TablePage',
        meta: {
            pageTitle: "日报数据",
            requireAuth: true,
            keepAlive: true
        },
        component: () => import("p/TablePage.vue"),
    },
    {
        path: "/Login",
        name: 'Login',
        meta: {
            pageTitle: "登录",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Login.vue"),
    },
    {
        path: "/Demo6Page",
        name: 'Demo6Page',
        meta: {
            pageTitle: "达人发布查看团购",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/Demo6Page.vue"),
    },
    {
        path: "/HotPersonPage",
        name: "HotPersonPage",
        meta: {
            pageTitle: "如猫添翼达人端",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/HotPersonPage.vue"),
    },
    {
        path: "/BusinessCooperationPage",
        name: "BusinessCooperationPage",
        meta: {
            pageTitle: "业务合作",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/BusinessCooperationPage.vue"),
    },
    {
        path: "/JoinUsPage",
        name: "JoinUsPage",
        meta: {
            pageTitle: "加入我们",
            requireAuth: false,
            keepAlive: true
        },
        component: () => import("p/JoinUsPage.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'NotFound',
        meta: {
            pageTitle: "404页面不存在",
            requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
            keepAlive: false
        },
        component: () => import("p/404.vue")
    }
];
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
});
export default router;